import { TextInput, LabelInput, Button, Link } from 'modules/v2/common/AtomicDesign/atoms';
import { useState, useEffect } from 'react';
import { PasswordInput } from 'modules/v2/common/AtomicDesign/molecules';
import { GoogleIcon, FacebookIcon } from 'modules/v2/common/components/SvgIcon';
import { checkEmail } from 'modules/v2/common/utils';
import LoginErrors from 'modules/v2/components/Login/LoginErrors';
import { useHistory } from 'react-router';
import { ROUTE_RESET_PASSWORD, ROUTE_NEW_PASSWORD } from 'modules/v2/routes/constants';
import { useQuery, useQueryClient } from 'react-query';
import { getLoginAuth } from 'modules/api/auth';
import { AuthenticationTemplate } from '../index';
import { USER_PASSWORD_STATUS } from './utils';

function Login({ handleChange, handleLogin, username = '', invalidEmail, statusCode, message }) {
  const history = useHistory();
  const queryClient = useQueryClient();
  const [emailIsValid, setEmailIsValid] = useState(null);

  const handleLoginData = async () => {
    if (checkEmail(username)) {

      try {
        if (typeof profitwell === 'function') {
          profitwell('user_email', username);
        } else {
          console.error('ProfitWell script not loaded.');
        }
      } catch (error) {
        console.error('ProfitWell error', error);
      }

      try {
        const user = await getLoginAuth(username);
        if (user?.passwordChangeRequired && user?.welcomeJourneyEligible) {
          setEmailIsValid(USER_PASSWORD_STATUS.PENDING);
        }

        if (!user?.passwordChangeRequired) {
          setEmailIsValid(USER_PASSWORD_STATUS.DONE);
        }
      } catch (error) {
        setEmailIsValid(USER_PASSWORD_STATUS.DONE);
      }
    }
  };

  const { isLoading } = useQuery('getLoginAuth', () => getLoginAuth(username), { enabled: false });

  useEffect(() => {
    queryClient.invalidateQueries('getLoginAuth');
    handleLoginData();
  }, [username]);

  const handleLoginPage = () => {
    if (emailIsValid === USER_PASSWORD_STATUS.DONE) {
      handleLogin();
    } else if (emailIsValid === USER_PASSWORD_STATUS.PENDING) {
      history.push(ROUTE_NEW_PASSWORD);
    }
  };
  const disableButton =
    !checkEmail(username) || isLoading || emailIsValid === USER_PASSWORD_STATUS.ERROR;

  return (
    <AuthenticationTemplate>
      <div className="w-full h-full flex flex-col items-center justify-center border-t border-neutral-200">
        <div className="w-[400px] h-full flex flex-col items-center justify-center mt-[150px] max-[450px]:w-full max-[450px]:px-5">
          <h2 className="font-bold text-2xl mb-[30px]">Welcome back to Authorify</h2>
          {/* <div className="w-full">
            <Button disabled type="outlined" size="lg" full className="mb-[10px]">
              <GoogleIcon className="mr-3" />
              Sign in with Google
            </Button>
            <Button disabled type="outlined" size="lg" full>
              <FacebookIcon className="mr-3" />
              Sign in with Facebook
            </Button>
            <div className="relative flex pt-[30px] items-center w-full">
              <div className="flex-grow border-t border-neutral-200" />
              <span className="flex-shrink mx-4 text-neutral-400">or</span>
              <div className="flex-grow border-t border-neutral-200" />
            </div>
          </div> */}
          {message && statusCode === 1 && (
            <div className="mt-[6px]">
              <LoginErrors
                invalidEmail={invalidEmail}
                statusCode={statusCode}
                statusMessage={message}
              />
            </div>
          )}
          <div className="w-full mb-3.5 mt-[30px]">
            <LabelInput>Email</LabelInput>
            <TextInput
              type="email"
              name="username"
              className="w-full"
              value={username}
              onChange={handleChange}
            />
          </div>
          {emailIsValid === USER_PASSWORD_STATUS.DONE ? (
            <div className="w-full mb-[30px]">
              <LabelInput>Password</LabelInput>
              <PasswordInput
                type="password"
                autoComplete="password"
                name="password"
                onChange={handleChange}
                onKeyPress={(e) => e.key === 'Enter' && handleLoginPage()}
              />
              <div className="flex justify-end mt-3">
                <span
                  className="text-neutral-800 font-medium underline cursor-pointer"
                  onClick={() => history.push(ROUTE_RESET_PASSWORD)}
                >
                  Forgot Password?
                </span>
              </div>
            </div>
          ) : null}
          <Button size="lg" full disabled={disableButton} onClick={handleLoginPage}>
            {emailIsValid === USER_PASSWORD_STATUS.DONE ? 'Log in' : 'Continue'}
          </Button>
        </div>
      </div>
    </AuthenticationTemplate>
  );
}

export default Login;
