import { map } from 'lodash-es';

export const formatLeads = (leads) =>
  map(leads, ({ segments, ...props }) => ({
    segments: map(segments, ({ id }) => id),
    ...props,
  }));

export const importLead = (json) => {
  const fields = Object.keys(json[0]);

  const replacer = (key, value) => {
    return value === null ? '' : value;
  };
  let csv = map(json, (row) => {
    return map(fields, (fieldName) => {
      return JSON.stringify(row[fieldName], replacer);
    });
  });
  csv.unshift(fields.join(','));
  csv = csv.join('\r\n');
  return csv;
};

export const handleQueryString = (object) => {
  let queryString = '';
  Object.entries(object).forEach(([key, value]) => {
    if (value !== '') queryString += `filters[${key}]=${value}&`;
  });
  return queryString.slice(0, -1);
};

export const serialize = (obj, prefix) => {
  const parts = [];
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      const newPrefix = prefix ? `${prefix}[${key}]` : key;
      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          parts.push(`${encodeURIComponent(newPrefix)}[]=${encodeURIComponent(value[i])}`);
        }
      } else if (typeof value === 'object' && value !== null) {
        const nested = serialize(value, newPrefix);
        if (nested !== '') {
          parts.push(nested);
        }
      } else {
        parts.push(`${encodeURIComponent(newPrefix)}=${encodeURIComponent(value)}`);
      }
    }
  }
  return parts.join('&');
};

export const removeNullOrEmptyProps = (obj) => {
  const newObj = {};
  Object.keys(obj).forEach((prop) => {
    if (obj[prop] !== null && obj[prop] !== undefined && obj[prop] !== '') {
      newObj[prop] = obj[prop];
    }
  });
  return newObj;
};

export const createDeleteLeadsSuccessMessage = (
  leadsPagination,
  unselectedLeads,
  selectedLeads,
  checkAll,
) => {
  if (checkAll) {
    const totalDeleted = leadsPagination.total - unselectedLeads.length;

    return `${totalDeleted} ${totalDeleted > 1 ? 'leads' : 'lead'} have been deleted`;
  }

  return selectedLeads.length > 1
    ? `${selectedLeads.length} leads have been deleted`
    : 'Lead has been deleted';
};
