import { cn } from 'modules/v2/common/utils/cn';

enum TaskType {
  Normal = 'normal',
  Shadow = 'shadow',
}

type TaskCardProps = {
  task: {
    title: string;
    name: string;
    icon: React.ReactNode;
    as: TaskType;
    onClick?: () => void;
  };
  children: React.ReactNode;
  className?: string;
  completedTask?: string;
};

/**
 * TaskCard component - Display a task card with title and icon
 *
 * @prop {string} title - The title of the task
 * @prop {React.ReactNode} icon - The icon of the task
 * @prop {AsType} as - The type of the task `normal` or `shadow`
 *
 * @returns
 */
export default function TaskCard({ children, task, className, completedTask }: TaskCardProps) {
  const { title, icon, as = TaskType.Normal, onClick } = task;
  const isDisabled = completedTask !== 'todo';
  return (
    <div
      className={cn(
        'w-full flex flex-col border border-neutral-200 justify-center rounded-lg cursor-default bg-white',
        {
          'shadow-box': as === TaskType.Shadow,
        },
        {
          'border border-neutral-200': as === TaskType.Normal,
        },
        {
          'cursor-pointer hover:border-primary-500 hover:border-1 hover:shadow-taskBox ': !isDisabled,
        },
        className,
      )}
      onClick={!isDisabled ? onClick : null}
      onKeyDown={null}
    >
      <div className="flex items-center gap-3 py-4 px-5 border-b border-neutral-200 h-[72px]">
        {icon}
        <span className="text-neutral-800 text-base font-medium leading-5">{title}</span>
      </div>
      <div>{children}</div>
    </div>
  );
}
