import { Alert } from 'flowbite-react';
import { AlertIcon } from 'modules/v2/common/components/SvgIcons';

import S from '../AuthenticationTemplate/index.styled';

const LoginMessage = ({ children }) => (
  <Alert className="w-full mt-6 bg-error-50 text-error-500 font-semibold text-sm justify-center">
    <div className="flex gap-2">
      <AlertIcon className="" />
      {children}
    </div>
  </Alert>
);

export default function LoginErrors({ invalidEmail, statusCode, statusMessage }) {
  let message;

  if (statusMessage === 'Inactive user') {
    message = (
      <div className="flex items-center flex-row">
        Your account is inactive. Please reach out to
        <a rel="noopener noreferrer" target="_blank" href="https://authorify.com/contact-us/">
          <span className="text-error-600 underline mx-[2px]">support</span>
        </a>
        for assistance.
      </div>
    );
  } else if (statusMessage === 'No books assigned') {
    message = (
      <>
        You don't have any assigned books. Please contact us using{' '}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://authorify.com/contact/"
          className="linked"
        >
          this form
        </a>
        .
      </>
    );
  } else if (statusMessage === 'Deleted user') {
    message = 'User not found.';
  } else if (invalidEmail) {
    message = 'Wrong email or password. Please try again';
  }

  if (statusMessage === "doesn't exist") {
    message = 'No account exists for that email address. Please try again.';
  }

  if (statusMessage === 'Illegal arguments: string, undefined') {
    message =
      'Your account has been disabled due failure to upsell. Please reach out to support for assistance.';
  }

  if (statusMessage === 'Request failed with status code 401') {
    message =
      'Your account has being disabled due failure to upsell. Please reach out to support for assistance.';
  }

  if (statusMessage.includes('Request failed with status code 404')) {
    message = 'No account exists for that email address. Please try again.';
  }

  if (statusMessage.includes('invalid password')) {
    message = 'Wrong email or password. Please try again';
  }

  if (!message) {
    return null;
  }

  return <LoginMessage>{message}</LoginMessage>;
}
