import { useState, useEffect } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useParams, useHistory } from 'react-router-dom';

import { map, get, isEmpty } from 'lodash';
import { Badge } from 'flowbite-react';
import classNames from 'classnames';
import InputMask from 'react-input-mask';
import { Formik } from 'formik';

import { isPilotCustomer } from 'modules/v2/utils/isPilotCustomer';
import { hasOnlyRMAccess } from 'modules/v2/utils/hasOnlyRMAccess';
import { notification } from 'modules/v2/common/utils/notify';

import {
  getEmailHistory,
  updateLeads,
  getEmailSegments,
  getStatesByCountryName,
  getLead,
} from 'modules/api';
import { formatDateTime5 } from 'modules/v2/common/utils';
import { DashboardTemplate } from 'modules/v2/templates';
import {
  getRouteLeads,
  getRouteReferralMarketingMagazineRecipients,
} from 'modules/v2/routes/navigation';
import { Box, ClosableModal, Button } from 'modules/v2/common/AtomicDesign/atoms';
import Table from 'modules/v2/common/components/Table';
import { AltArrowRight, PinIcon, PencilIcon } from 'modules/v2/common/components/SvgIcon';
import { ErrorState } from 'modules/v2/common/components';
import validationSchema from '../Leads/components/EditLeads/ValidationSchema';
import EditLeads from '../Leads/components/EditLeads';

import S from './styles';

const EmailHistory = () => {
  const history = useHistory();
  const [originalLead, setOriginalLead] = useState({});
  const [leadDetails, setLeadDetails] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [leadStatus, setLeadStatus] = useState('');
  const [editData, setEditData] = useState({});
  const showRmFields = isPilotCustomer() && hasOnlyRMAccess();
  const [loadingTitle, setLoadingTitle] = useState('Loading Leads');
  const [loaderDescription, setLoaderDescription] = useState('Please wait while Loading...');
  const { mutateAsync, isLoading: isEditLoading } = useMutation(updateLeads);
  const queryClient = useQueryClient();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { leadId, origin } = useParams();
  const perPage = 10;

  const getSegments = (leadData) => {
    if (leadData?.allSegments) {
      return 'All';
    }
    if (Array.isArray(leadData?.segments)) {
      return leadData?.segments?.map((segment) => segment.name).join(', ');
    }
    if (typeof leadData?.segments === 'string') {
      return leadData?.segments;
    }
    return '';
  };

  const fetchLeadDetails = async () => {
    const { data: leadData } = await getLead(leadId);

    const {
      address: { address1 = '', address2 = '', city = '', state = '', country = '', zip = '' } = {},
      ...restLeadData
    } = leadData;

    const formattedAddress = [address1, address2, city, state, country, zip]
      .filter(Boolean)
      .join(', ');

    setOriginalLead({ ...restLeadData, address1, address2, city, state, country, zip });
    setLeadDetails({ ...restLeadData, address: formattedAddress, segments: getSegments(leadData) });
  };

  useEffect(() => {
    fetchLeadDetails();
  }, [leadId]);

  const { isError, isLoading, data } = useQuery(
    ['gettingLeadsHistory', { leadId, perPage, currentPage, leadStatus }],
    () => getEmailHistory(leadId, perPage, currentPage, leadStatus),
    { keepPreviousData: true },
  );
  const historyData = get(data, 'data.data');

  const historyDataPagination = get(data, 'data.meta', []);

  const { data: segmentsList } = useQuery(['gettingSegments'], getEmailSegments);
  const segmentsData = get(segmentsList, 'data');
  const Canada = 'Canada';
  const USA = 'USA';
  const { data: states } = useQuery(['getStatesByCountryName', { Canada, USA }], () =>
    getStatesByCountryName(Canada, USA),
  );
  const countries = ['USA', 'Canada'];
  const stateList = get(states, 'data.data', {});
  const canada = [];
  const usa = [];
  Object.keys(stateList).forEach((x) => {
    if (stateList[x].country === 'Canada') {
      canada.push(stateList[x].state);
    } else {
      usa.push(stateList[x].state);
    }
  });
  const statesObject = {
    Canada: canada,
    USA: usa,
  };
  const pageChange = (e) => {
    setCurrentPage(e);
  };

  const leadHistoryOptions = [
    { value: 'All', key: 'All' },
    { value: 'Bounced', key: 'Bounced' },
    { value: 'Opened', key: 'Opened' },
    { value: 'Rejected', key: 'Rejected' },
    { value: 'Spam', key: 'Spam' },
    { value: 'Success', key: 'Success' },
    { value: 'Unsubscribed', key: 'Unsubscribed' },
  ];

  const onStatusChange = (e) => {
    if (e.find((status) => status === 'All')) {
      setLeadStatus('All');
    } else {
      setLeadStatus(e.join(','));
    }
    if (!e || !e.length) {
      setLeadStatus('');
    }
  };
  const historyParsed = map(historyData, ({ createdAt, id, status, templateName, title, type }) => {
    return {
      createdAt,
      id,
      status,
      templateName,
      title,
      type,
    };
  });
  const pagination = {
    pageSize: historyDataPagination.perPage,
    total: historyDataPagination.total,
    lastPage: historyDataPagination.lastPage,
    currentPage: historyDataPagination.currentPage,
    onChange: pageChange,
  };
  const columns = [
    {
      title: 'Campaign name',
      dataIndex: 'campaignName',
      key: 'campaignName',
      width: '290px',
    },
    {
      title: 'Template',
      dataIndex: 'templateName',
      key: 'templateName',
      width: '220px',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '220px',
      render: (status) => (
        <Badge
          className={classNames(
            status === ('Sent' || 'Active')
              ? 'text-success-500 bg-success-50'
              : 'text-error-500 bg-error-50',
            'w-fit rounded-md',
          )}
        >
          {status}
        </Badge>
      ),
    },
    {
      title: 'Date email sent',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '290px',
      render: (createdAt) => <span>{formatDateTime5(createdAt)}</span>,
    },
  ];

  const getLeadRequest = (lead) => {
    const leadRequest = {};
    const leadAddress = {};
    leadAddress.address1 = lead.address1;
    leadAddress.address2 = lead.address2;
    leadAddress.city = lead.city;
    leadAddress.country = lead.country;
    leadAddress.zip = lead.zip;
    leadAddress.state = lead.state;
    leadRequest.address = leadAddress;
    leadRequest.email = lead.email;
    leadRequest.firstName = lead.firstName;
    leadRequest.lastName = lead.lastName;
    leadRequest.phone = lead.phone;
    leadRequest.segments = lead.segments;
    leadRequest.allSegments = lead.allSegments;
    leadRequest.id = lead.id;
    leadRequest.isRMEligible = lead.isRm;
    return leadRequest;
  };

  const onEditLead = async (lead) => {
    if (!lead.isRm && showRmFields) {
      lead.address1 = '';
      lead.address2 = '';
      lead.city = '';
      lead.zip = '';
      lead.state = '';
      lead.country = '';
    }

    if (lead.segments.length && !lead.allSegments) {
      if (lead.segments && Object.keys(lead.segments[0]).length > 0 && !lead.allSegments) {
        lead.segments = lead.segments.map((l) => l.id);
      }
    }
    setLoadingTitle('Updating your lead');
    setLoaderDescription('Please wait while we update your lead.');
    const leadRequest = { ...{}, ...getLeadRequest(lead) };
    const allSegmentSelected = leadRequest?.allSegments;
    if (allSegmentSelected) {
      leadRequest.segments = [];
    } else {
      leadRequest.allSegments = false;
    }
    await mutateAsync(leadRequest)
      .then(() => {
        fetchLeadDetails();
        queryClient.invalidateQueries('gettingLeads');
        notification.success({
          description: 'Lead updated successfully',
          autoClose: 3000,
        });
      })
      .catch(() => {
        notification.error({
          description:
            'The system has encountered an error. Please try again or contact customer support!!!',
          autoClose: 3000,
        });
      });
  };

  const openEditModal = async () => {
    setEditData(originalLead);
    setIsModalOpen(true);
  };

  const closeEditModal = () => {
    setIsModalOpen(false);
  };

  const getNavigationDetails = () => {
    if (origin === 'Leads') {
      return getRouteLeads();
    }
    if (origin === 'Magazine') {
      return getRouteReferralMarketingMagazineRecipients();
    }
    return null;
  };

  return (
    <DashboardTemplate
      isError={isError}
      hasSidebar
      isLoadind={isEditLoading}
      loadingTitle={loadingTitle}
      noDataTitle="No leads found"
      loaderDescription={loaderDescription}
      error={
        <ErrorState
          topMessage={
            <>
              Sorry, our server encounters an error while processing and loading your Lead details.{' '}
              <br />
              Please try again later.
            </>
          }
          bottomMessage={
            <>
              If the issue continues, please let our team know by{' '}
              <a href="/help-center">clicking here.</a>
            </>
          }
        />
      }
    >
      <div>
        <div className="mb-6">
          <div className="flex items-center gap-2.5 mb-6">
            <div
              className="text-sm text-neutral-600 cursor-pointer mb-0.5"
              onClick={() => history.push(getNavigationDetails())}
            >
              {origin}
            </div>
            <div className="mb-0.5">
              <AltArrowRight fill="#757575" />
            </div>
            <div className="text-sm text-neutral-700 border-b-2 border-neutral-200 mb">Details</div>
          </div>
          <S.NameContainer className="text-lg">
            {leadDetails?.firstName} {leadDetails?.lastName}
            {leadDetails?.isRMEligible && (
              <S.PinIconWrapper>
                <PinIcon color="#FF8A33" />
              </S.PinIconWrapper>
            )}
          </S.NameContainer>
          <S.BadgeContainer className="gap-1">
            {!leadDetails?.unsubscribed && (
              <S.Badge>
                <S.DotIcon fill="#3AB886" />
                Subscribed
              </S.Badge>
            )}
            <S.Badge>
              <S.DownloadIcon fill="#3AB886" />
              Manually added
            </S.Badge>
          </S.BadgeContainer>
        </div>
        <Box>
          <div className="px-6 py-[22px] max-[520px]:flex-col max-[520px]:gap-y-2">
            <div className="px-1 py-[10px] border-b flex items-center justify-between max-[520px]:flex-col max-[520px]:gap-y-2">
              <span className="font-semibold text-base">Details</span>
              <S.EditButton onClick={() => openEditModal()}>
                <PencilIcon />
              </S.EditButton>
            </div>

            <div className="border-b flex h-[54px] items-center">
              <span className="w-[20%] text-neutral-500">First name</span>
              <span className="w-[30%] font-semibold">{leadDetails?.firstName}</span>
              <span className="w-[20%] text-neutral-500">Last name</span>
              <span className="w-[30%] font-semibold">{leadDetails?.lastName}</span>
            </div>

            <div className="border-b flex h-[54px] items-center">
              <span className="w-[20%] text-neutral-500">Email address</span>
              <span className="w-[30%] font-semibold">{leadDetails?.email}</span>
              <span className="w-[20%] text-neutral-500">Phone number</span>
              <span className="w-[30%] font-semibold">
                <S.LeadPhone>
                  <InputMask mask="+1 (999) 999-9999" value={leadDetails?.phone} readOnly disabled>
                    {(inputProps) => <input {...inputProps} type="text" />}
                  </InputMask>
                </S.LeadPhone>
              </span>
            </div>

            <div className="border-b flex h-[54px] items-center">
              <span className="w-[20%] text-neutral-500">Address</span>
              <span className="w-[80%] font-semibold">{leadDetails?.address}</span>
            </div>

            <div className="border-b flex h-[54px] items-center">
              <span className="w-[20%] text-neutral-500">Segments</span>
              <div className="w-[80%] flex gap-2">
                {leadDetails?.segments &&
                  leadDetails?.segments.split(',').map((segment, index) => (
                    <span key={index[0]} className="bg-gray-100 text-gray-700 px-2 py-1 rounded">
                      {segment.trim()}
                    </span>
                  ))}
              </div>
            </div>
          </div>
        </Box>

        <Box>
          <div className="px-6 py-[22px] flex items-center justify-between max-[520px]:flex-col max-[520px]:gap-y-2">
            <span className="font-semibold text-base">Email history</span>
            <S.Select
              className="max-w-[300px] min-w-[200px] sm:w-full"
              mode="multiple"
              defaultValue="All"
              showArrow
              onChange={onStatusChange}
              maxTagCount={8}
              style={{ width: 160 }}
              options={leadHistoryOptions}
            />
          </div>
          <Table
            rowKey="id"
            hasData={!isEmpty(historyParsed)}
            dataSource={historyParsed}
            columns={columns}
            loading={isLoading}
            pagination={{
              ...pagination,
              current: currentPage,
              onChange: pageChange,
            }}
          />
          <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={{
              ...editData,
              addressLine1: editData?.address1,
              pincode: editData?.zip,
              isRm: editData?.address?.address1 !== '' && editData?.isRMEligible,
              segments: editData?.segments?.map((d) => d.id),
            }}
            onSubmit={onEditLead}
          >
            {({ handleSubmit, isValid, dirty }) => (
              <ClosableModal
                title="Edit Lead"
                showModal={isModalOpen}
                setShowModal={closeEditModal}
                footer={
                  <Button
                    className="h-12"
                    full
                    disabled={!isValid || !dirty}
                    buttonType="submit"
                    onClick={() => {
                      handleSubmit();
                      closeEditModal();
                    }}
                  >
                    Save changes
                  </Button>
                }
              >
                <EditLeads
                  segmentsData={segmentsData}
                  disabled={!isValid || !dirty}
                  countries={countries}
                  states={statesObject}
                  showRmFields={showRmFields}
                />
              </ClosableModal>
            )}
          </Formik>
        </Box>
      </div>
    </DashboardTemplate>
  );
};

export default EmailHistory;
