import { useEffect, useState } from 'react';
import { Form, useFormikContext } from 'formik';
import { get, map } from 'lodash-es';
import BaseInputMask from 'react-input-mask';

import { useSelector } from 'react-redux';
import { SelectMultiField, SelectField } from 'modules/v2/common/components';
import { TextInput, SwitchButton } from 'modules/v2/common/AtomicDesign/atoms';
import { PinIcon } from 'modules/v2/common/components/SvgIcon';
import AddressGoogleLookup from 'modules/v2/common/components/AddressGoogleLookup';
import { LabelInput } from 'modules/v2/common/AtomicDesign/atoms/';
import { APIProvider, Map } from '@vis.gl/react-google-maps';
import env from '../../../../../../environments';
import S from './style';

const EditLeads = ({ countries, states, segmentsData, isAddLead }) => {
  const { isRm: showRmFields, isBooks } = useSelector((data) => data.bundleAccess);
  const { values, errors, touched, handleBlur, handleChange, setFieldValue } = useFormikContext();

  const optionBuilder = (item) => ({ title: item, value: item });

  const selectedCountry = values?.country;
  const selectedStateList = get(states, selectedCountry);
  const countryList = map(countries, optionBuilder);
  const stateList = map(selectedStateList, optionBuilder);
  const handleCountryChange = () => setFieldValue('state', undefined);
  const setIsRm = () => {
    setFieldValue('isRm', !values.isRm);
  };
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [geocodeLocation, setGeocoderLocation] = useState(null);
  const API_KEY = env.GOOGLE_MAP_API_KEY;

  useEffect(() => {
    if (values.country === 'CA') {
      setFieldValue('country', 'Canada');
    }
  }, [values]);

  const isErrorMessage = (errorValue, touchedValue) => {
    return errorValue && touchedValue && <span className="text-error-500 mt-2">{errorValue}</span>;
  };

  return (
    <div className="h-fit">
      <div className="mb-6">
        <Form>
          <div className="flex gap-3.5 mb-3.5">
            <S.FieldControl>
              <LabelInput required>First name</LabelInput>
              <TextInput
                id="firstName"
                name="firstName"
                value={values?.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {isErrorMessage(errors.firstName, touched.firstName)}
            </S.FieldControl>
            <S.FieldControl>
              <LabelInput required>Last name</LabelInput>
              <TextInput
                id="lastName"
                name="lastName"
                value={values?.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {isErrorMessage(errors.lastName, touched.lastName)}
            </S.FieldControl>
          </div>
          <div className="flex gap-3.5 mb-3.5">
            <S.FieldControl>
              <LabelInput required>Email address</LabelInput>
              <TextInput
                id="email"
                name="email"
                value={values?.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {isErrorMessage(errors.email, touched.email)}
            </S.FieldControl>
          </div>
          <div className="flex gap-3.5 mb-3.5">
            <div className="flex flex-col w-full">
              <LabelInput required>Phone number</LabelInput>
              <BaseInputMask
                id="phone"
                name="phone"
                value={values?.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                mask="+1 (999) 999-9999"
                className="border w-full h-[46px] rounded-md pl-5"
              />
              {isErrorMessage(errors.phone, touched.phone)}
            </div>
          </div>
          <div className="flex flex-col mb-6 w-full">
            <LabelInput required>Add to segment</LabelInput>
            <S.BorderlessFormikField
              disabled={showRmFields && !isBooks}
              component={SelectMultiField}
              name="segments"
              placeholder="Select segment"
              type="text"
              options={segmentsData}
              maxCount="8"
              selectedSegment={values && values?.segments && values?.segments.map((d) => d.id || d)}
            />
            {isErrorMessage(errors.segments, touched.segments)}
          </div>
          {showRmFields && (
            <div className="flex flex-row items-center gap-3.5 font-semibold">
              <SwitchButton id="isRm" name="isRm" checked={values?.isRm} onChange={setIsRm} />
              <span>For Referral Magazine (RM) shipments</span>
            </div>
          )}
          {values?.isRm || !showRmFields ? (
            <>
              <div className="mt-6">
                <APIProvider apiKey={API_KEY} libraries={['marker']}>
                  <div className="hidden">
                    <Map
                      mapId="bf51a910020fa25a"
                      style={{
                        width: '100%',
                        height: selectedPlace && '250px',
                        borderRadius: '8px',
                      }}
                      defaultZoom={15}
                      defaultCenter={{
                        lat: selectedPlace?.geometry?.location.lat(),
                        lng: selectedPlace?.geometry?.location.lat(),
                      }}
                      gestureHandling="greedy"
                      disableDefaultUI
                    />
                  </div>

                  <AddressGoogleLookup
                    setSelectedPlace={setSelectedPlace}
                    setGeocoderLocation={setGeocoderLocation}
                    setFieldValue={setFieldValue}
                    isAddLead
                    required={showRmFields}
                    initialInputValue={values.addressLine1}
                  />
                </APIProvider>
              </div>
              {selectedPlace || !isAddLead ? (
                <>
                  <div className="mt-3.5 flex flex-col">
                    <LabelInput required={showRmFields}>Country</LabelInput>
                    <div>
                      <S.BorderlessFormikField
                        component={SelectField}
                        name="country"
                        placeholder="Select"
                        type="text"
                        options={countryList}
                        $hasError={errors.country && touched.country}
                        onChange={handleCountryChange}
                        onBlur={handleCountryChange}
                        value={values?.country}
                      />
                    </div>
                    {isErrorMessage(errors.country, touched.country)}
                  </div>
                  <div className="flex flex-col mt-3.5">
                    <LabelInput>Apartment, suite, unit, building, etc.</LabelInput>
                    <TextInput
                      id="address2"
                      name="address2"
                      placeholder=""
                      $hasError={errors.address2 && touched.address2}
                      value={values?.address2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {isErrorMessage(errors.address2, touched.address2)}
                  </div>
                  <div className="flex flex-col mt-3.5">
                    <LabelInput required={showRmFields}>City</LabelInput>
                    <TextInput
                      id="city"
                      name="city"
                      placeholder=""
                      value={values?.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      $hasError={errors.city && touched.city}
                    />
                    {isErrorMessage(errors.city, touched.city)}
                  </div>
                  <div className="flex flex-col mt-3.5">
                    <LabelInput required={showRmFields}>State/Province</LabelInput>
                    <S.SelectContainer>
                      <S.BorderlessFormikField
                        component={SelectField}
                        name="state"
                        placeholder="Select"
                        options={stateList}
                        value={values?.state}
                        $hasError={errors.state && touched.state}
                      />
                    </S.SelectContainer>
                    {isErrorMessage(errors.state, touched.state)}
                  </div>
                  <div className="flex flex-col mt-3.5">
                    <LabelInput required={showRmFields}>ZIP/Postal code</LabelInput>
                    <TextInput
                      id="pincode"
                      name="pincode"
                      placeholder=""
                      value={values?.pincode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      $hasError={errors.pincode}
                    />
                    {isErrorMessage(errors.pincode, true)}
                  </div>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </Form>
      </div>
    </div>
  );
};

export default EditLeads;
