import { compose } from 'redux';
import { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { FileInput, Label } from 'flowbite-react';
import { Formik } from 'formik';
import { get } from 'lodash-es';
import classNames from 'classnames';

import { withProfile } from 'modules/v2/containers';
import { getEmailSegments, importLeads } from 'modules/api';
import { notification } from 'modules/v2/common/utils/notify';
import { DashboardTemplate } from 'modules/v2/templates';
import { getRouteLeads } from 'modules/v2/routes/navigation';
import useUserData from 'modules/v2/utils/hooks/useUserData';
import { SelectMultiField } from 'modules/v2/common/components';
import { Anchor, Box, Button, SwitchButton } from 'modules/v2/common/AtomicDesign/atoms';
import { ProcessingModal } from 'modules/v2/common/AtomicDesign/organisms';
import {
  AltArrowRight,
  PlusCircleIcon,
  SpreadsheetIcon,
  ImportCheck,
  DangerTriangleIcon,
  CircleCheck,
  DownloadIcon,
} from 'modules/v2/common/components/SvgIcon';
import { customLoadingModalTheme } from 'modules/v2/pages/OrderedBooksListingPage/Checkout/BookCheckout/theme';
import { DownloadMinimalisticIcon } from 'modules/common/components';
import { importLead, formatFailedLeads } from 'modules/dashboard/pages/Leads/utils';
import { ALL_SEGMENTS_ID } from 'modules/v2/pages/CreateOnDemandEmail';
import * as XLSX from 'xlsx';
import { danger500 } from 'modules/common/theme/color';
import { useSelector } from 'react-redux';
import { formatInvalidLeads } from 'v2/pages/ImportLeads/utils';
import { WarningMessage } from './WarningMessage';
import importLeadSchema from './validationSchema';

const ImportLeads = () => {
  const { isRm: showRmToggle, isBooks } = useSelector((data) => data.bundleAccess);
  const history = useHistory();
  const [filters, setFilters] = useState({ segments: [] });
  const [isDragOver, setIsDragOver] = useState(false);
  const [importedFile, setImportedFile] = useState();
  const [fixLeadsImported, setFixLeadsImported] = useState(false);
  const [isFileValidationError, setIsFileValidationError] = useState(false);
  const [isRm, setIsRm] = useState(false);
  const [importData, setImportData] = useState();
  const [importHeaders, setImportHeaders] = useState();
  const [errorImportLeads, setErrorImportLeads] = useState([]);
  const userData = useUserData();
  const fileInputRef = useRef(null);

  const requiredGeneralHeaders = ['first name', 'last name', 'email', 'phone number'];
  const addressHeaders = ['address1', 'city', 'zip', 'state', 'country'];

  useEffect(() => {
    setIsRm(showRmToggle && !isBooks);
  }, [isBooks, showRmToggle]);

  useEffect(() => {
    if (isFileValidationError && !importedFile) {
      fileInputRef.current.value = null;
    }
  }, [isFileValidationError]);

  const queryClient = useQueryClient();

  const { data: segmentsList } = useQuery('getEmailSegments', getEmailSegments, {
    onSuccess: ({ data }) => {
      data.unshift({ id: ALL_SEGMENTS_ID, name: 'Select all' });

      if (showRmToggle && !isBooks) {
        const segmentId = [data?.find((segment) => segment.name === 'RM Mailing List').id];

        setFilters({
          ...filters,
          segments: segmentId,
        });
      }
    },
  });

  const segmentsData = get(segmentsList, 'data')?.filter((segment) => {
    if (!showRmToggle && isBooks) {
      return segment.name !== 'RM Mailing List';
    }

    return true;
  });

  const isRmFileValidation = (isAttach, jsonData) => {
    const rows = jsonData.slice(1);
    const headers = jsonData[0].map((header) => header.trim().toLowerCase());
    const indexOfReqProp = [];

    let breakRowsValidation = false;

    for (let i = 0; i < addressHeaders.length; i++) {
      if (!headers.includes(addressHeaders[i])) {
        isAttach = false;

        break;
      } else {
        indexOfReqProp.push(headers.indexOf(addressHeaders[i]));
      }
    }

    for (let i = 0; i < rows.length; i++) {
      for (let j = 0; j < indexOfReqProp.length; j++) {
        if (!rows[i][indexOfReqProp[j]]) {
          isAttach = false;
          breakRowsValidation = true;

          break;
        }
      }
      if (breakRowsValidation) {
        break;
      }
    }

    return isAttach;
  };

  const { mutate: importLeadsMutation, isLoading: isImportLoading } = useMutation(importLeads, {
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries('gettingLeads');

      setImportData(data);
      setErrorImportLeads(formatInvalidLeads(data, importHeaders));
      setFixLeadsImported(true);
    },
    onError: () => {
      notification.error({
        description:
          'The system has encountered an error. Please try again or contact customer support.',
      });
    },
  });

  const generateFileReaderVars = (e) => {
    const data = new Uint8Array(e.target.result);
    const workbook = XLSX.read(data, { type: 'array' });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
    const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

    const headers = jsonData[0]
      .map((header) => header.trim().toLowerCase())
      .filter((header) => [...requiredGeneralHeaders, ...addressHeaders].includes(header));

    return { headers, jsonData };
  };

  const validateSheetContents = (headers, isAttach, jsonData) => {
    // Required general headers validation
    for (let i = 0; i < requiredGeneralHeaders.length; i++) {
      if (!headers.includes(requiredGeneralHeaders[i])) {
        isAttach = false;
        break;
      }
    }

    if (isAttach) {
      if (isRm) {
        isAttach = isRmFileValidation(isAttach, jsonData);
      }
    }

    if (!isAttach) {
      setIsFileValidationError(true);
    }

    return isAttach;
  };

  const handleFiles = (files) => {
    const fileType = files[0]?.type;
    const allowedFileTypes = [
      'text/csv',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ];

    if (!allowedFileTypes.includes(fileType)) {
      notification.error({
        description: `Please upload a .csv, .xls or .xlsx file, ${files[0]?.type} is not allowed.`,
      });
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const { headers, jsonData } = generateFileReaderVars(e);

      setImportHeaders(headers);

      let isAttach = true;

      isAttach = validateSheetContents(headers, isAttach, jsonData);

      if (isAttach) {
        setImportedFile(files[0]);
        setIsFileValidationError(false);
      }
    };
    reader.readAsArrayBuffer(files[0]);
  };

  const handleToggleValidation = () => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const { jsonData } = generateFileReaderVars(e);

      let isAttach = true;

      isAttach = isRmFileValidation(isAttach, jsonData);

      if (!isAttach) {
        setImportedFile(null);
        setIsFileValidationError(true);
      }
    };
    reader.readAsArrayBuffer(importedFile);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);

    const { files } = e.dataTransfer;
    handleFiles(files);
  };

  const handleFileInputChange = (e) => {
    const { files } = e.target;
    handleFiles(files);
  };

  const handleSwitchChange = () => {
    setIsRm(!isRm);
    if (importedFile) {
      handleToggleValidation();
    } else {
      setIsFileValidationError(false);
    }
  };

  const uploadFile = (data) => {
    const formData = new FormData();
    formData.append('file', importedFile, importedFile.name);
    formData.append('customerEmail', userData.email);
    formData.append('allSegments', false);
    formData.append('isRMEligible', isRm);
    formData.append('isImport', data?.isImport);

    const allSegments = filters.segments.includes(ALL_SEGMENTS_ID);
    const segments = filters.segments.filter((id) => id !== ALL_SEGMENTS_ID);

    if (allSegments) {
      setFilters(segments);
      formData.set('allSegments', true);
    } else {
      const newSegmentsList = [];
      segments.forEach((segment, index) => {
        newSegmentsList.push(segment);
        formData.append(`segments[${index}]`, segment);
      });
    }
    importLeadsMutation(formData);
  };

  function renderImportFile() {
    return (
      <div className="p-6 bg-neutral-50 rounded-md flex gap-3.5">
        <SpreadsheetIcon />
        <div className="flex w-full justify-between items-center">
          <div>
            <p className="font-semibold text-sm mb-0.5">{importedFile.name}</p>
          </div>
          <div>
            <Anchor onClick={() => setImportedFile(null)} theme="danger">
              Delete file
            </Anchor>
          </div>
        </div>
      </div>
    );
  }

  function renderImportFileDropZone() {
    return (
      <div className="flex w-full items-center justify-center">
        <Label
          htmlFor="dropzone-file"
          className={classNames(
            'flex h-[185px] w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-neutral-200 hover:bg-neutral-50',
            isDragOver ? 'bg-neutral-50' : 'bg-white',
          )}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <div className="flex flex-col items-center justify-center pb-6 pt-5 font-semibold">
            <div className="w-10 h-10 rounded-full bg-neutral-200 flex items-center justify-center">
              <PlusCircleIcon />
            </div>
            <p className="mb-2 text-sm text-neutral-600">
              <span className="text-primary-500">Upload file</span> or drag and drop
            </p>
            <p className="text-xs text-neutral-400">.csv only</p>
          </div>
          <FileInput
            ref={fileInputRef}
            id="dropzone-file"
            className="hidden"
            onChange={handleFileInputChange}
            accept=".csv, .xls, .xlsx"
          />
        </Label>
      </div>
    );
  }

  const downloadSampleFile = () => {
    const json = [
      {
        'First Name': 'John',
        'Last Name': 'Doe',
        Email: 'johndoe@mail.com',
        'Phone Number': '7778889996',
        Address1: '1234 Broadway',
        Address2: 'Apt 5B',
        City: 'New York',
        Zip: '10001',
        State: 'NY',
        Country: 'USA',
      },
    ];

    const exportFileName = 'sampleLead.csv';
    const blob = new Blob([importLead(json)], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, exportFileName);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', exportFileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  const downloadFailedImports = () => {
    const exportFileName = 'Failed-leads.csv';
    const blob = new Blob([formatFailedLeads(importHeaders, errorImportLeads)], {
      type: 'text/csv;charset=utf-8;',
    });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', exportFileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  function renderFixImportedLeads() {
    const { successCount, duplicatedCount, invalidImportListCount } = importData;

    return (
      <div className="flex flex-col">
        {(duplicatedCount > 0 || invalidImportListCount > 0) &&
          WarningMessage(
            'Invalid leads found due to incorrect details. For duplicates, choose one lead to import. Please fix invalid details and upload them again.',
          )}
        <div className="flex flex-col gap-3 mt-3">
          <div className="border-b flex h-[34px]">
            <span className="w-[30%] text-neutral-500 ">Good leads</span>
            <div className="w-[70%] flex gap-2">
              <span className="text-success-500 font-semibold flex">{successCount}</span>
              <CircleCheck />
            </div>
          </div>
          <div className="border-b flex h-[34px]">
            <span className="w-[30%] text-neutral-500">Invalid leads</span>
            <div className="w-[70%] flex gap-2">
              <span className="text-danger-500 font-semibold">
                {duplicatedCount + invalidImportListCount}
              </span>
              <DangerTriangleIcon fill={danger500} />
            </div>
          </div>
          <div className="flex h-[34px]">
            <span className="w-[30%] text-neutral-500">Segment</span>
            <div className="w-[70%] flex gap-2">
              {filters.segments.map((segment) => {
                return (
                  <span
                    key={segment}
                    className="text-xs font-semibold py-1 px-2 bg-neutral-100 rounded-[6px] h-fit"
                  >
                    {segmentsData.find((details) => details.id === segment).name}
                  </span>
                );
              })}
            </div>
          </div>
        </div>
        {(duplicatedCount > 0 || invalidImportListCount > 0) && (
          <div className="w-full overflow-auto mb-6  rounded-lg ">
            <table className="table-fixed w-full">
              <thead>
                <tr>
                  {importHeaders.map((title) => {
                    const formattedTitle = title
                      .split(' ')
                      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                      .join(' ');
                    return (
                      <th
                        key={formattedTitle}
                        className="w-[254px] py-3 px-6 text-left bg-neutral-100 border border-neutral-200 font-semibold"
                      >
                        {formattedTitle}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {errorImportLeads.map((invalidLead) => {
                  return (
                    <tr>
                      {invalidLead.map((element) => {
                        return (
                          <td className="border border-neutral-200 py-[18px] px-6">
                            <div className="flex flex-col gap-1">
                              <span>{element.value}</span>
                              <span className="text-xs text-danger-500 font-semibold">
                                {element.message}
                              </span>
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}

        <div className="flex gap-3.5">
          {duplicatedCount > 0 || invalidImportListCount > 0 ? (
            <Button type="outlined" onClick={downloadFailedImports}>
              <div className="mr-2">
                <DownloadIcon />
              </div>
              Download failed leads .csv
            </Button>
          ) : (
            <Button type="outlined" onClick={() => history.push(getRouteLeads())}>
              Cancel
            </Button>
          )}

          <Button
            onClick={() => {
              uploadFile({ isImport: true });
              history.push(getRouteLeads());
            }}
            disabled={successCount === 0}
          >
            Import leads
          </Button>
        </div>
      </div>
    );
  }

  function renderImportLeadsCSV(formik) {
    return (
      <>
        <div>
          {isFileValidationError &&
            WarningMessage(
              isRm
                ? 'We encountered an issue while importing leads for RM Magazine. Make sure you have the columns First Name, Last Name, Email, Phone Number, Address1, City, Zip, State, and Country. Also make sure that leads have complete and valid addresses.'
                : 'We encountered an issue while importing your leads. Make sure you have the columns First Name, Last Name, Email, and Phone Number.',
            )}

          <div className="flex justify-between mb-1 items-center max-[620px]:flex-col max-[620px]:gap-y-2 max-[620px]:items-start">
            <div>
              <div className="font-semibold">Upload file</div>
              <div className="text-sm text-neutral-400 font-medium -mb-2">
                Select a file containing your leads to import.
              </div>
            </div>
            <Button type="outlined" size="sm" onClick={downloadSampleFile}>
              <DownloadMinimalisticIcon className="mr-2" />
              Download sample file (.csv)
            </Button>
          </div>
          {importedFile ? renderImportFile() : renderImportFileDropZone()}
        </div>
        <div className="text-sm text-neutral-400 font-medium my-6">
          Disclaimer: Please make sure that you have proper permission from all leads you are
          importing, otherwise their email platform may tag your emails as spam.
        </div>
        <div className="mb-[24px]">
          <div className="font-semibold">Select segment</div>
          <SelectMultiField
            className="w-full border border-neutral-200 rounded-md"
            placeholder="Select"
            disabled={showRmToggle && !isBooks}
            options={segmentsData || []}
            name="segments"
            onBlur={() => {
              if (filters.segments.length === 0) formik.setFieldTouched('segments', true, false);
            }}
            onChange={(segmentIdArray) => {
              setFilters({
                ...filters,
                segments: segmentIdArray,
              });
              if (filters.segments.length === 0) formik.setFieldTouched('segments', true, false);
            }}
            value={filters.segments}
            maxCount={8}
          />
          {formik.touched.segments && filters.segments && filters.segments.length === 0 && (
            <div className="text-error-500 text-sm mt-1">Segments are required</div>
          )}
        </div>
        {showRmToggle && (
          <div className=" mb-[34px] flex gap-4">
            <SwitchButton checked={isRm} onChange={handleSwitchChange} />
            <div className="flex flex-col">
              <span className="text-sm font-semibold">
                Importing leads for Referral Magazine (RM) shipments
              </span>
              <span className="text-sm font-medium text-neutral-500">
                Please provide the full address details in the following format: Address1, City,
                State, Zip, Country
              </span>
            </div>
          </div>
        )}

        <Button
          type="primary"
          onClick={() => uploadFile({ isImport: false })}
          disabled={
            !formik.isValid ||
            formik.isSubmitting ||
            (filters.segments && filters.segments.length === 0) ||
            !importedFile
          }
        >
          Continue
        </Button>
      </>
    );
  }

  return (
    <DashboardTemplate hasSidebar>
      <div className="mb-6">
        <div className="flex items-center gap-2.5 mb-6">
          <div
            className="text-sm text-neutral-600 cursor-pointer mb-0.5"
            onClick={() => history.push(getRouteLeads())}
          >
            Leads
          </div>
          <div className="mb-0.5">
            <AltArrowRight fill="#757575" />
          </div>
          <div className="text-sm text-neutral-700 border-b-2 border-neutral-200 mb">Details</div>
        </div>
      </div>
      {fixLeadsImported && (
        <Box className="flex justify-between py-3.5 px-6">
          <div className="flex gap-4 items-center">
            <ImportCheck />
            <div className="flex flex-col">
              <span className="font-semibold text-base">Import leads from a file</span>
              <span className="font-medium text-sm text-neutral-500">{importedFile?.name}</span>
            </div>
          </div>
          <Button
            type="outlined"
            size="base"
            className="px-6"
            onClick={() => {
              setFixLeadsImported(false);
            }}
          >
            Edit
          </Button>
        </Box>
      )}

      <Box>
        <div className="text-base font-semibold px-6 py-[22px]  text-boxTitle border-b border-neutral-200">
          {fixLeadsImported ? 'Review your import' : 'Import leads from a file'}
        </div>
        <Formik
          enableReinitialize
          onSubmit={uploadFile}
          validateOnMount
          validationSchema={importLeadSchema}
        >
          {(formik) => {
            return (
              <div className="p-6">
                {fixLeadsImported ? renderFixImportedLeads() : renderImportLeadsCSV(formik)}
              </div>
            );
          }}
        </Formik>
      </Box>
      <ProcessingModal
        title="Validating Leads"
        description="Please don’t close this window"
        showModal={isImportLoading}
        theme={customLoadingModalTheme}
      />
    </DashboardTemplate>
  );
};

export default compose(withProfile)(ImportLeads);
