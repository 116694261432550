function CloseIconWhite() {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 10L10 18M9.99997 10L17.9999 18"
        stroke="#FAFAFA"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default CloseIconWhite;
