import axios from 'axios';
import qs from 'query-string';
import { get, merge } from 'lodash-es';

import { getItem } from 'modules/dashboard/utils/legacy';
import env from 'environments';

const instance = axios.create({
  baseURL: env.BBA_URL,
  // baseURL: 'https://bba.dev.authorify.com',
  paramsSerializer: qs.stringify,
});

instance.interceptors.request.use((config) => {
  const token = getItem('token');
  const Authorization = `Bearer ${token}`;

  return merge({}, config, {
    headers: {
      Authorization,
    },
  });
});

instance.interceptors.response.use(
  (config) => {
    const status = get(config, ['data', 'status']);
    if (status === false) {
      return Promise.reject(config);
    }
    return config;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      window.location = '/auth/login';
    }
    return Promise.reject(new Error('401 Unauthorized Error'));
  },
);

const getInstance = () => instance;

export default getInstance;
