import axios from 'axios';
import qs from 'query-string';
import { get, merge } from 'lodash-es';

import env from 'environments';

import { getItem } from 'modules/dashboard/utils/legacy';

const instance = axios.create({
  baseURL: env.BASE_URL,
  paramsSerializer: qs.stringify,
});

instance.interceptors.request.use((config) => {
  const token = getItem('token');
  const Authorization = `Bearer ${token}`;

  return merge({}, config, {
    headers: {
      Authorization,
    },
  });
});

// Checks the response body and rejects case the request failed
// TODO: This is a workaround because the API is not returning the proper status codes
instance.interceptors.response.use(
  (config) => {
    const status = get(config, ['data', 'status'], true);

    if (status === false) {
      return Promise.reject(config);
    }

    return config;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      window.location = '/auth/login';
    }
    return Promise.reject(new Error('401 Unauthorized Error'));
  },
);

const getInstance = () => instance;

export default getInstance;
