import { compose } from 'redux';
import { createContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { debounce, find, get, isEmpty, map } from 'lodash-es';
import { Checkbox, Tooltip } from 'flowbite-react';
import { nanoid } from 'nanoid';
import { Formik } from 'formik';
import { BrewIcon } from 'modules/common/components/SvgIcon';
import io from 'socket.io-client';
import env from 'environments';

import { defaultCheckboxTheme } from 'modules/v2/common/AtomicDesign/atoms/Checkbox/theme';
import Table from 'modules/v2/common/components/Table';
import {
  Box,
  Button,
  ClosableModal,
  SwitchButton,
  TextInput,
} from 'modules/v2/common/AtomicDesign/atoms';
import { DeleteModal } from 'modules/v2/common/AtomicDesign/organisms';
import {
  CheckIconNew,
  DownloadIcon,
  PencilIcon,
  RestartIcon,
  TrashIcon,
  PinIcon,
} from 'modules/v2/common/components/SvgIcon';

import {
  bulkLeadsDelete,
  bulkLeadsDeleteAll,
  deleteLeads,
  downloadLeads,
  getEmailSegments,
  getLeadsList,
  getStatesByCountryName,
  importLeads,
  saveLeads,
  updateLeads,
  getLeadsStatus,
} from 'modules/api';

import { ErrorState, Modal, NoContent, SelectMultiField } from 'modules/v2/common/components';
import { DashboardTemplate } from 'modules/v2/templates';
import { getRouteEmailHistory, getRouteImportLeads } from 'modules/v2/routes/navigation';

import { notification } from 'modules/v2/common/utils/notify';
import { withProfile } from 'modules/dashboard/containers';
import { formatDateTime5 } from 'modules/v2/common/utils';
import { element } from 'modules/common/utils/element';
import { useSelector } from 'react-redux';
import validationSchema from './components/EditLeads/ValidationSchema';

import EditLeads from './components/EditLeads';
import ImportLeads from './components/ImportLeads';
import ImportedLeadsFix from './components/ImportedLeadsFix';
import SegmentsLeads from './components/SegmentsLeads';
import DeleteLeads from './components/DeleteLeads';
import { createDeleteLeadsSuccessMessage, removeNullOrEmptyProps, serialize } from './utils';
import S from './styles';

export const LeadsFixContext = createContext(null);

const Leads = ({ profile }) => {
  const { isRm: showRmFields, isBooks } = useSelector((data) => data.bundleAccess);
  const history = useHistory();

  const [fixLeadsImported, setFixLeadsImported] = useState(false);
  const queryClient = useQueryClient();
  const memberEmail = get(profile, 'data.email');
  const [sortBy, setSortBy] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [importVisible, setImportVisible] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [leadId, setLeadId] = useState();
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [newImportLead, setNewImportLead] = useState({});
  const [segmentList, setSegmentList] = useState([]);
  const [isAllSegments, setIsAllSegments] = useState(false);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [allSelectedLeads, setAllSelectedLeads] = useState(false);
  const [loadingTitle, setLoadingTitle] = useState('Loading Leads');
  const [loaderDescription, setLoaderDescription] = useState('Please wait while Loading...');
  const [filters, setFilters] = useState({});
  const [selectedSegments, setSelectedSegments] = useState([]);
  const [isValidEmails, setIsValidEmails] = useState(false);
  const [unsubscribedLeads, setUnsubscribedLeads] = useState(false);
  const [currentLeadsPage, setCurrentLeadsPage] = useState(1);
  const [leadsPagination, setLeadsPagination] = useState({
    pageSize: 15,
    perPage: 15,
    current: 1,
  });
  const [showSegments, setShowSegments] = useState([]);
  const [showImportLeadsBanner, setShowImportLeadsBanner] = useState(false);
  const [leadsData, setLeadsData] = useState([]);
  const [leadsParsed, setLeadsParsed] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [unselectedLeads, setUnselectedLeads] = useState([]);

  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentLeadsPage]);

  useEffect(() => {
    setUnselectedLeads([]);
  }, [checkAll]);

  useEffect(() => {
    const socket = io(env.SOCKET_IO);

    let customerId;

    const fetchStatus = async () => {
      const value = await getLeadsStatus();
      setShowImportLeadsBanner(value.data.isLoading);
      customerId = value.data.customerId;

      socket.on(`importLeadsStatus ${customerId}`, (data) => {
        setShowImportLeadsBanner(data.isLoading);
      });
    };

    fetchStatus();

    return () => {
      if (customerId) {
        socket.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [noSavedLeads, setNoSavedLeads] = useState([]);
  const { perPage } = leadsPagination;

  const formatSegments = (segments) => {
    if (!segments.length) return 'All Segments';
    if (!segments) return '-';
    return segments.map((l) => l.name).join(', ');
  };

  const { isError: isLeadError, isLoading: isLeadsListLoading, data: leads } = useQuery(
    ['gettingLeads', { perPage, currentLeadsPage, sortBy, filters }],
    () =>
      getLeadsList(
        perPage,
        currentLeadsPage,
        sortBy,
        serialize(removeNullOrEmptyProps(filters), 'filters'),
      ),
    {
      cacheTime: 0,
      staleTime: 0,
      onSuccess: (data) => {
        const meta = get(data, 'data.meta', {});
        const { perPage: newPerPage, currentPage: current, total } = meta;

        let formatedLeadsData = get(data, 'data.data');
        if (formatedLeadsData) {
          formatedLeadsData = formatedLeadsData?.map((lead) => {
            if (lead.address) {
              /* eslint-disable no-param-reassign */
              Object.assign(lead, lead.address);
              delete lead.address;
            }
            return lead;
          });
        }
        const parsedLeadsData = map(formatedLeadsData, ({ segments, ...params }) => ({
          ...params,
          segments: formatSegments(segments),
        }));

        if (checkAll) {
          const selectedIds = [];
          parsedLeadsData.forEach((lead) => {
            if (!unselectedLeads.includes(lead.id)) {
              selectedIds.push(lead.id);
            }
          });

          setSelectedLeads(selectedIds);
        }

        setLeadsParsed(parsedLeadsData);
        setLeadsData(formatedLeadsData);

        setLeadsPagination({
          pageSize: newPerPage,
          size: perPage,
          perPage,
          total,
          current,
        });
      },
    },
  );

  const { data: segmentsList } = useQuery(['gettingSegments'], getEmailSegments);
  const segmentsData = get(segmentsList, 'data')?.filter((segment) => {
    if (!showRmFields && isBooks) {
      return segment.name !== 'RM Mailing List';
    }

    if (showRmFields && !isBooks) {
      return segment.name === 'RM Mailing List';
    }

    return true;
  });
  const Canada = 'Canada';
  const USA = 'USA';
  const { data: states } = useQuery(['getStatesByCountryName', { Canada, USA }], () =>
    getStatesByCountryName(Canada, USA),
  );

  const handleCancelLeadsFix = () => {
    setFixLeadsImported(false);
    queryClient.invalidateQueries('gettingLeads');
  };

  const { mutateAsync: getCSVLeadsMutation, isLoading: isExportLoading } = useMutation(
    downloadLeads,
  );

  const getLeadRequest = (lead) => {
    const leadRequest = {};
    const address = {};
    address.address1 = lead.addressLine1;
    address.address2 = lead.address2;
    address.city = lead.city;
    address.country = lead.country;
    address.zip = lead.pincode;
    address.state = lead.state;
    leadRequest.address = address;
    leadRequest.email = lead.email;
    leadRequest.firstName = lead.firstName;
    leadRequest.lastName = lead.lastName;
    leadRequest.phone = lead.phone;
    leadRequest.segments = lead.segments;
    leadRequest.allSegments = lead.allSegments;
    leadRequest.id = lead.id;
    leadRequest.isRMEligible = lead.isRm;
    return leadRequest;
  };

  const getCSVLeads = () => {
    getCSVLeadsMutation()
      .then((data) => {
        element(data, 'Leads');
      })
      .catch(() => {
        toast.dismiss();
        toast.error(
          'Sorry, an error occurs while exporting your leads. Please try again or contact our customer support.',
          {
            className: 'Errortoaster',
            position: toast.POSITION.TOP_RIGHT,
          },
        );
      });
  };

  const { mutateAsync, isLoading: isEditLoading } = useMutation(updateLeads);
  const { mutateAsync: saveLeadsMutation, isLoading: isSaveLoading } = useMutation(saveLeads);
  const { mutateAsync: deleteLeadsMutation, isLoading: isDeleteLoading } = useMutation(deleteLeads);
  const { mutateAsync: bulkLeadsDeleteMutation, isLoading: isBulkDeleteLoading } = useMutation(
    bulkLeadsDelete,
  );
  const {
    mutateAsync: bulkLeadsDeleteAllMutation,
    isLoading: isBulkDeleteAllLoading,
  } = useMutation(bulkLeadsDeleteAll);
  const { mutate: importLeadsMutation, isLoading: isImportLoading } = useMutation(importLeads, {
    onSuccess: ({ data }) => {
      const { invalidList } = data || {};

      if (Array.isArray(invalidList) && invalidList.length) {
        const invalidListWithIds = invalidList.map(({ firstName, lastName, email, phone }) => ({
          firstName,
          lastName,
          email,
          phone,
          key: nanoid(),
        }));

        setNoSavedLeads(invalidListWithIds);
        setFixLeadsImported(true);
        queryClient.invalidateQueries('gettingLeads');
        return;
      }

      setIsAllSegments(false);
      queryClient.invalidateQueries('gettingLeads');
    },
    onError: () => {
      notification.error({
        description:
          'The system has encountered an error. Please try again or contact customer support!!!',
      });
    },
  });

  const handleSelectLead = (leadsId, checked) => {
    setAllSelectedLeads(false);

    if (checked) {
      setSelectedLeads((prevSelectedLeads) => [...prevSelectedLeads, leadsId]);
    } else {
      setSelectedLeads((prevSelectedLeads) => prevSelectedLeads.filter((id) => id !== leadsId));
    }

    if (checkAll) {
      if (checked) {
        setUnselectedLeads((prevUnselectedLeads) =>
          prevUnselectedLeads.filter((id) => id !== leadsId),
        );
      } else {
        setUnselectedLeads((prevUnselectedLeads) => [...prevUnselectedLeads, leadsId]);
      }
    }
  };

  const onEditLead = async (lead) => {
    if (!lead.isRm && showRmFields) {
      lead.address1 = '';
      lead.address2 = '';
      lead.city = '';
      lead.zip = '';
      lead.state = '';
      lead.country = '';
    }

    if (lead.segments.length && !lead.allSegments) {
      if (lead.segments && Object.keys(lead.segments[0]).length > 0 && !lead.allSegments) {
        lead.segments = lead.segments.map((l) => l.id);
      }
    }
    setLoadingTitle('Updating your lead');
    setLoaderDescription('Please wait while we update your lead.');
    const leadRequest = { ...{}, ...getLeadRequest(lead) };
    const allSegmentSelected = leadRequest?.allSegments;
    if (allSegmentSelected) {
      leadRequest.segments = [];
    } else {
      leadRequest.allSegments = false;
    }
    await mutateAsync(leadRequest)
      .then(() => {
        queryClient.invalidateQueries('gettingLeads');
        notification.success({
          description: 'Lead created successfully',
          autoClose: 3000,
        });
      })
      .catch((error) => {
        notification.error({
          description:
            error.response?.data?.errors?.[0] ||
            'The system has encountered an error. Please try again or contact customer support!!!',
          autoClose: 3000,
        });
      });
  };

  const throwDeleteNotificationError = () => {
    return notification.error({
      description:
        'The system has encountered an error. Please try again or contact customer support!!!',
      autoClose: 3000,
    });
  };

  const onMultipleDeleteLeads = async (leadsId) => {
    setLoadingTitle('Deleting Your Lead');
    setLoaderDescription('Please wait while we delete your lead.');

    if (checkAll) {
      await bulkLeadsDeleteAllMutation(unselectedLeads).catch(() => {
        throwDeleteNotificationError();
      });
    } else {
      await bulkLeadsDeleteMutation(leadsId).catch(() => {
        throwDeleteNotificationError();
      });
    }
  };

  const deleteMultipleLeads = async () => {
    await onMultipleDeleteLeads(selectedLeads);

    setCheckAll(false);
    setSelectedLeads([]);

    notification.success({
      title: 'Delete lead',
      description: createDeleteLeadsSuccessMessage(
        leadsPagination,
        unselectedLeads,
        selectedLeads,
        checkAll,
      ),
    });

    setDeleteModal(false);
    queryClient.invalidateQueries('gettingLeads');
  };

  const deleteLead = () => {
    setLoadingTitle('Deleting your lead');
    setLoaderDescription('Please wait while we Delete your lead.');

    deleteLeadsMutation(leadId)
      .then(() => {
        queryClient.invalidateQueries('gettingLeads');
        notification.success({
          description: 'Lead has been deleted',
          autoClose: 3000,
        });
      })
      .catch(() => {
        notification.error({
          description:
            'The system has encountered an error. Please try again or contact customer support!!!',
          autoClose: 3000,
        });
      });
  };

  const onMultipleEditLeads = async (lead) => {
    setLoadingTitle('Updating Your Leads');
    setLoaderDescription('Please wait while we Update your leads...');

    await mutateAsync(lead).catch(() => {
      notification.error({
        description:
          'The system has encountered an error. Please try again or contact customer support!!!',
        autoClose: 3000,
      });
    });
  };

  const getSelectedLeadsObjects = () =>
    checkAll ? leadsData : leadsData.filter((lead) => selectedLeads.includes(lead.id));

  const createLeadRequest = (lead) => ({
    ...lead,
    allSegments: isAllSegments,
    segments: isAllSegments ? [] : segmentList,
    bookId: 'testBook123',
  });

  const handleUpdateMultipleLeadsSuccess = () => {
    setCheckAll(false);
    setIsAllSegments(false);
    setSelectedLeads([]);
    setAllSelectedLeads(false);
    queryClient.invalidateQueries('gettingLeads');
    notification.success({
      description: 'Lead(s) moved to segments successfully',
      autoClose: 3000,
    });
  };

  const handleUpdateMultipleLeadsError = () => {
    notification.error({
      description: 'Error while moving leads to segments',
      autoClose: 3000,
    });
  };

  const buildLeadUpdateParams = () => ({
    allSegments: isAllSegments,
    segments: segmentList.length > 0 ? segmentList : [],
  });

  const handleLeadUpdate = async (leadUpdateParams) => {
    if (allSelectedLeads) {
      await onMultipleEditLeads({ ...leadUpdateParams, selectedAll: true });
    } else {
      const selectedLeadsObjects = getSelectedLeadsObjects();
      const leadRequests = selectedLeadsObjects.map(createLeadRequest);
      await Promise.all(leadRequests.map(onMultipleEditLeads));
    }
  };

  const updateMultipleLeads = async () => {
    const leadUpdateParams = buildLeadUpdateParams();

    try {
      await handleLeadUpdate(leadUpdateParams);
      handleUpdateMultipleLeadsSuccess();
    } catch (error) {
      handleUpdateMultipleLeadsError();
    }
  };

  const onSaveLead = async (newLead) => {
    setModalVisible(false);
    setLoadingTitle('Adding Your Lead');
    setLoaderDescription('Please wait while we add your lead.');
    const leadRequest = { ...{}, ...getLeadRequest(newLead) };
    const allSegmentSelected = leadRequest?.allSegments;
    if (allSegmentSelected) {
      leadRequest.segments = [];
    } else {
      leadRequest.allSegments = false;
    }
    saveLeadsMutation({ ...leadRequest, bookId: 11 })
      .then(() => {
        notification.success({
          description: 'Lead created successfully',
          autoClose: 3000,
        });
        queryClient.invalidateQueries('gettingLeads');
      })
      .catch((error) => {
        notification.error({
          description:
            error.response?.data?.errors?.[0] ||
            'The system has encountered an error. Please try again or contact customer support!!!',
          autoClose: 3000,
        });
      });
  };

  const handleImportLeads = () => {
    setImportVisible(false);
    const formData = new FormData();
    formData.append('bookId', 'Groucho');
    formData.append('customerEmail', memberEmail);
    const newSegmentsList = [];

    segmentList.forEach((segment, index) => {
      newSegmentsList.push(segment);
      formData.append(`segments[${index}]`, segment);
    });

    formData.append('allSegments', isAllSegments);
    setSelectedSegments(isAllSegments ? [] : newSegmentsList);

    formData.append('file', newImportLead.originFileObj);

    setLoadingTitle('Importing Leads');
    setLoaderDescription('Please wait while we import your lead.');

    importLeadsMutation(formData);
  };

  const addLeadClick = () => {
    setModalVisible(true);
  };

  const countries = ['USA', 'Canada'];
  const stateList = get(states, 'data.data', {});
  const canada = [];
  const usa = [];
  Object.keys(stateList).forEach((x) => {
    if (stateList[x].country === 'Canada') {
      canada.push(stateList[x].state);
    } else {
      usa.push(stateList[x].state);
    }
  });

  const statesObject = {
    Canada: canada,
    USA: usa,
  };

  const getOriginalLead = (originalId) => find(leadsData, ({ id }) => id === originalId);

  const handleSelectAll = (e) => {
    setAllSelectedLeads(false);
    setCheckAll(e.target.checked);
    const { checked } = e.target;
    const selectedIds = checked ? leadsParsed.map((lead) => lead.id) : [];
    setSelectedLeads(selectedIds);
  };

  const handleSelectAllButton = () => {
    const newSelectedAllLeads = !allSelectedLeads;
    handleSelectAll({ target: { checked: newSelectedAllLeads } });
    setAllSelectedLeads(newSelectedAllLeads);
    setCheckAll(newSelectedAllLeads);
  };

  const tableColumns = [
    {
      title: (
        <Checkbox
          className="cursor-pointer"
          theme={defaultCheckboxTheme}
          checked={checkAll}
          onChange={handleSelectAll}
        />
      ),
      key: 'checkbox',
      width: '30px',
      fixed: windowSize > 1200 ? 'left' : false,
      render: (leadData) => (
        <Checkbox
          className="cursor-pointer"
          checked={selectedLeads.includes(leadData.id)}
          onChange={(e) => handleSelectLead(leadData.id, e.target.checked)}
          theme={defaultCheckboxTheme}
        />
      ),
    },
    {
      title: 'Name',
      dataIndex: 'firstName',
      key: 'firstName',
      fixed: windowSize > 1200 ? 'left' : false,
      width: '230px',
      sorter: (a, b, sortDirection) => {
        setSortBy(`[firstName]:${sortDirection.slice(0, -3)}`);
        return a.firstName.localeCompare(b.firstName);
      },
      render: (value, { lastName, email, phone, isRMEligible, id }) => (
        <div
          style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer' }}
          role="button"
          tabIndex={0}
          onClick={() => history.push(getRouteEmailHistory(id, 'Leads'))}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault();
              history.push(getRouteEmailHistory(id, 'Leads'));
            }
          }}
        >
          <S.NameContainer>
            {`${value} ${lastName}`}
            {isRMEligible && (
              <S.PinIconWrapper>
                <PinIcon color="#FF8A33" />
              </S.PinIconWrapper>
            )}
          </S.NameContainer>
          <S.EmailPhoneText>{email}</S.EmailPhoneText>
          <S.EmailPhoneText>{phone}</S.EmailPhoneText>
        </div>
      ),
    },
    {
      title: 'Segments',
      dataIndex: 'segments',
      width: '120px',
      key: 'segments',
      sorter: (a, b, sortDirection) => {
        setSortBy(`[segments]:${sortDirection.slice(0, -3)}`);
        return a.segments.localeCompare(b.segments);
      },
      render: (segments, { id }) => {
        const parts = segments.split(', ');
        const firstTwoItems = parts.slice(0, 2);
        const removedCount = parts.length - firstTwoItems.length;
        const renderSegments = (segmentsArr) =>
          segmentsArr.map((segment) => (
            <div
              key={segment}
              className="bg-neutral-100 w-fit rounded-md px-2 py-1 whitespace-nowrap"
            >
              {segment}
            </div>
          ));

        return (
          <span className="flex flex-wrap gap-1 max-w-[160px]">
            {showSegments.includes(id) ? renderSegments(parts) : renderSegments(firstTwoItems)}
            {parts.length > 2 &&
              (!showSegments.includes(id) ? (
                <Button
                  size="s"
                  type="outlined"
                  className="px-2"
                  onClick={() => {
                    setShowSegments([...showSegments, id]);
                  }}
                >
                  +{removedCount}
                </Button>
              ) : (
                <Button
                  size="s"
                  type="outlined"
                  className="px-2"
                  onClick={() => {
                    const index = showSegments.indexOf(id);
                    showSegments.splice(index, 1);

                    setShowSegments([...showSegments]);
                  }}
                >
                  show less
                </Button>
              ))}
          </span>
        );
      },
    },
    {
      title: 'valid?',
      dataIndex: 'isValid',
      width: '80px',
      key: 'isValid',
      render: (isValid) => (isValid ? 'Yes' : 'No'),
      sorter: (a, b, sortDirection) => {
        setSortBy(`[isValid]:${sortDirection.slice(0, -3)}`);
        return Number(a.isValid) - Number(b.isValid);
      },
    },
    {
      title: 'Unsubscribed?',
      dataIndex: 'unsubscribed',
      width: '130px',
      key: 'unsubscribed',
      render: (unsubscribed) => (unsubscribed ? 'Yes' : 'No'),
      sorter: (a, b, sortDirection) => {
        setSortBy(`[unsubscribed]:${sortDirection.slice(0, -3)}`);
        return Number(a.unsubscribed) - Number(b.unsubscribed);
      },
    },
    {
      title: 'Date added',
      dataIndex: 'createdAt',
      width: '140px',
      key: 'createdAt',
      render: (createdAt) => <span>{formatDateTime5(createdAt)}</span>,
      sorter: (a, b, sortDirection) => {
        setSortBy(`[createdAt]:${sortDirection.slice(0, -3)}`);
        return new Date(a.createdAt) - new Date(b.createdAt);
      },
    },
    {
      title: 'Action',
      key: 'action',
      width: '130px',
      fixed: windowSize > 1200 ? 'right' : false,
      render: (lead, { id }) => {
        return (
          <div className="flex flex-nowrap gap-2">
            <Button
              size="sm"
              type="muted"
              onClick={() => {
                setEditData(getOriginalLead(id));
                setEditModal(true);
              }}
            >
              <PencilIcon />
            </Button>
            <Button
              size="sm"
              type="muted"
              onClick={() => {
                setLeadId(id);
                setDeleteModal(true);
              }}
            >
              <TrashIcon fill="#252525" />
            </Button>
          </div>
        );
      },
    },
  ];

  const loadingTable = isLeadsListLoading || isEditLoading || isSaveLoading || isDeleteLoading;

  const handleClearFilters = () => {
    setFilters({});
    setIsValidEmails(false);
    setUnsubscribedLeads(false);
  };

  const pushFilter = (key, value) => {
    setFilters({ ...filters, [key]: value });
  };

  const removeFilter = (key) => {
    const { [key]: _, ...restFilters } = filters;
    setFilters(restFilters);
  };

  const hasFilters = () => {
    return Object.keys(filters).length > 0;
  };

  const handleFilter = debounce((e) => {
    const { value } = e.target;
    pushFilter('email', value);
  }, 500);

  const handleDeleteLead = () => {
    if (allSelectedLeads) return deleteMultipleLeads();
    const isChecked = selectedLeads.length > 0;
    if (isChecked) return deleteMultipleLeads();
    return deleteLead();
  };

  return (
    <DashboardTemplate
      isError={isLeadError}
      isLoading={
        isEditLoading ||
        isSaveLoading ||
        isDeleteLoading ||
        isImportLoading ||
        isExportLoading ||
        isBulkDeleteLoading ||
        isBulkDeleteAllLoading
      }
      hasSidebar
      contentTitle="Leads"
      loadingTitle={loadingTitle}
      noDataTitle="No Leads Available"
      loaderDescription={loaderDescription}
      error={
        <ErrorState
          topMessage={
            <>
              Sorry, our server encounters an error while processing and loading your Leads. <br />
              Please try again later.
            </>
          }
          bottomMessage={
            <>
              If the issue continues, please let our team know by{' '}
              <a href="/help-center">clicking here.</a>
            </>
          }
        />
      }
    >
      <div className="flex items-center justify-between gap-6 flex-wrap mb-4">
        <h1 className="text-2xl font-bold p-0">Leads</h1>
        <div className="flex gap-2">
          <Button type="outlined" onClick={addLeadClick}>
            Add Lead
          </Button>
          <Button onClick={() => history.push(getRouteImportLeads())}>Import leads</Button>
        </div>
      </div>
      {showImportLeadsBanner && (
        <Box className="py-[14px] px-6 flex items-center mb-6">
          <BrewIcon />
          <div className="w-full h-fit ml-2">
            <h4>We're preparing your brew...</h4>
            <p>
              This should be ready in a few minutes. We will notify you when it's ready. You can
              cancel this at any time if you made a mistake.
            </p>
          </div>
          {/* Removed for now, button functionality still in progress */}
          {/* <Button type="bare" size="xs" className="py-[13px] px-[18px]">
            <div className="flex flex-row gap-2 items-center flex-nowrap">
              <StopIcon />
              <h5 className="text-red-500 text-[14px] font-700 font-bold whitespace-nowrap m-0">
                Stop brewing
              </h5>
            </div>
          </Button> */}
        </Box>
      )}

      <Box className="p-y-6">
        <div className="flex  gap-3 items-center flex-wrap py-[22px] px-6 border-b border-neutral-200  max-[770px]:justify-center">
          <TextInput
            placeholder="Search for name or email"
            onChange={handleFilter}
            className="w-[320px]"
            sizing="md"
            errorMessage="Invalid input"
          />
          <div className="flex items-center gap-3">
            <SwitchButton
              checked={isValidEmails}
              setChecked={setIsValidEmails}
              onChange={() => {
                if (!isValidEmails) {
                  return pushFilter('isValid', !isValidEmails);
                }
                return removeFilter('isValid');
              }}
            />
            <span className="text-sm font-semibold">Valid emails</span>
          </div>
          <div className="flex items-center gap-3">
            <SwitchButton
              checked={unsubscribedLeads}
              setChecked={setUnsubscribedLeads}
              onChange={() => {
                if (!unsubscribedLeads) {
                  return pushFilter('unsubscribed', !unsubscribedLeads);
                }
                return removeFilter('unsubscribed');
              }}
            />
            <span className="text-sm font-semibold">Unsubscribed leads</span>
          </div>
          <Button type="muted" size="sm" onClick={handleClearFilters} disabled={!hasFilters()}>
            <RestartIcon />
          </Button>

          <div className="ml-auto  max-[1250px]:ml-0">
            <Button type="muted" onClick={getCSVLeads} disabled={leadsParsed.length === 0}>
              <div className="flex items-center gap-2">
                <DownloadIcon />
                <span>Export</span>
              </div>
            </Button>
          </div>
        </div>
        <div className="py-[22px] px-6 flex items-center justify-between flex-wrap gap-2">
          <SelectMultiField
            className="max-w-[300px] min-w-[200px] sm:w-full rounded-md"
            name="segments"
            placeholder="Select segment"
            type="text"
            options={[...(segmentsData || [])]}
            onChange={(segmentIdArray) => setFilters({ ...filters, segments: segmentIdArray })}
            value={filters.segments}
            maxCount={8}
          />
          {(selectedLeads?.length > 0 || checkAll) && (
            <div className="flex items-center gap-3.5 flex-wrap">
              <div className="flex items-center gap-2 max-sm:flex-wrap">
                <div className="flex items-center gap-2 border-r border-neutral-200">
                  <Checkbox
                    theme={defaultCheckboxTheme}
                    onChange={(e) => {
                      setIsAllSegments(e.target.checked);
                    }}
                  />
                  <span className="text-sm font-semibold pr-3.5">All segments</span>
                </div>
                <span className="text-sm font-semibold pr-3.5">Add To</span>
                <SegmentsLeads
                  segmentsData={segmentsData}
                  getSegmentList={setSegmentList}
                  isAllSegments={isAllSegments}
                />
              </div>
              <Tooltip content="Save">
                <Button
                  type="muted"
                  size="sm"
                  onClick={updateMultipleLeads}
                  disabled={!segmentList || (!segmentList.length && !isAllSegments)}
                >
                  <CheckIconNew fill="#069668" />
                </Button>
              </Tooltip>
              <Tooltip content="Delete">
                <Button type="muted" size="sm" onClick={() => setDeleteModal(true)}>
                  <TrashIcon />
                </Button>
              </Tooltip>
            </div>
          )}
          <Button
            type={allSelectedLeads ? 'primary' : 'muted'}
            onClick={handleSelectAllButton}
            disabled={leadsParsed.length === 0}
          >
            <div className="flex items-center gap-2">
              <span>Select All</span>
            </div>
          </Button>
        </div>
        {isLeadsListLoading || !isEmpty(leadsData) ? (
          <Table
            rowKey="id"
            columns={tableColumns}
            dataSource={leadsParsed}
            loading={loadingTable}
            scroll={{ x: 'max-content' }}
            pagination={{
              ...leadsPagination,
              current: currentLeadsPage,
              onChange: (page) => setCurrentLeadsPage(page),
            }}
          />
        ) : (
          <NoContent message="No Leads Found" />
        )}
        <LeadsFixContext.Provider value={{ noSavedLeads, setNoSavedLeads }}>
          <ImportedLeadsFix
            isOpen={fixLeadsImported}
            setIsOpen={setFixLeadsImported}
            onCancel={handleCancelLeadsFix}
            data={noSavedLeads}
            selectedSegments={selectedSegments}
          />
        </LeadsFixContext.Provider>
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={onSaveLead}
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
            country: '',
            isRm: showRmFields && !isBooks,
            segments: showRmFields && !isBooks ? segmentsData?.map((d) => d.id) : [],
            allSegments: false,
          }}
        >
          {({ handleSubmit, isValid, dirty }) => (
            <ClosableModal
              title="Add new lead"
              showModal={modalVisible}
              setShowModal={setModalVisible}
              footer={
                <Button
                  className="h-12"
                  full
                  onClick={() => {
                    handleSubmit();
                    setModalVisible(false);
                  }}
                  disabled={!isValid || !dirty}
                >
                  Add new lead
                </Button>
              }
            >
              <EditLeads
                segmentsData={segmentsData}
                disabled={!isValid || !dirty}
                countries={countries}
                states={statesObject}
                isAddLead
              />
            </ClosableModal>
          )}
        </Formik>
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={{
            ...editData,
            addressLine1: editData.address1,
            pincode: editData.zip,
            isRm: editData.address1 !== '' && showRmFields,
            segments: editData.segments?.map((d) => d.id),
          }}
          onSubmit={onEditLead}
        >
          {({ handleSubmit, isValid, dirty }) => (
            <ClosableModal
              title="Edit Lead"
              isOpen={modalVisible}
              showModal={editModal}
              setShowModal={setEditModal}
              footer={
                <Button
                  className="h-12"
                  full
                  disabled={!isValid || !dirty}
                  buttonType="submit"
                  onClick={() => {
                    handleSubmit();
                    setEditModal(false);
                  }}
                >
                  Save changes
                </Button>
              }
            >
              <EditLeads
                segmentsData={segmentsData}
                disabled={!isValid || !dirty}
                countries={countries}
                states={statesObject}
              />
            </ClosableModal>
          )}
        </Formik>
        <Modal
          title="Import Lead(s)"
          isOpen={importVisible}
          onConfirm={handleImportLeads}
          onCancel={() => setImportVisible(false)}
          okText="Import Lead(s)"
          okButtonProps={{
            disabled: !Object.keys(newImportLead).length || (!segmentList.length && !isAllSegments),
          }}
        >
          <ImportLeads
            getData={setNewImportLead}
            segmentsData={segmentsData}
            getSegmentList={setSegmentList}
            getIsAllSegments={setIsAllSegments}
          />
        </Modal>
        <DeleteModal
          title="Delete lead permanently"
          showModal={deleteModal}
          setShowModal={setDeleteModal}
          handleDelete={() => handleDeleteLead()}
        >
          <DeleteLeads
            count={checkAll ? leadsPagination.total - unselectedLeads.length : selectedLeads.length}
            allSelectedLeads={allSelectedLeads}
          />
        </DeleteModal>
      </Box>
    </DashboardTemplate>
  );
};

export default compose(withProfile)(Leads);
