import { BookPreview as IBookPreview } from 'modules/v2/pages/admin/BookPreviews/types';
import { Book } from 'modules/v2/pages/BookCatalog/types';
import { Space } from 'antd';
import { EyeOpenIcon } from 'modules/v2/common/components/SvgIcons';
import BookList from '../BookList';
import S from './styles';

const BookPreview = ({
  book,
  currentBookPreview,
  onClickHandle,
  linkUrl,
  selectedBook,
}: {
  book: Book;
  currentBookPreview: IBookPreview;
  onClickHandle: (url: any) => void;
  linkUrl: string;
  selectedBook: string;
}) => {
  const { name: bookName, title: bookTitle, thumbnail: bookThumbnail, isAllowed } = book;
  const isSelectedBook = selectedBook === currentBookPreview?.bookId;
  if (!bookName) return null;

  return (
    <S.Preview isSelectedBook={isSelectedBook}>
      <BookList>
        <S.GuideContainer>
          <S.ImageContainer>
            <S.Image src={bookThumbnail?.url} className="image-container" />
            <S.ButtonContainer>
              <S.ButtonGroup>
                <Space direction="vertical">
                  <S.Button type="primary" size="middle" href={linkUrl}>
                    {currentBookPreview?.milestone ? 'View details' : 'Customize'}
                  </S.Button>

                  <S.WhiteButton
                    type="primary"
                    onClick={() => onClickHandle(currentBookPreview)}
                    size="middle"
                  >
                    <div className="flex gap-3 items-center">
                      <EyeOpenIcon /> Preview
                    </div>
                  </S.WhiteButton>
                </Space>
              </S.ButtonGroup>
            </S.ButtonContainer>
          </S.ImageContainer>
        </S.GuideContainer>
      </BookList>
    </S.Preview>
  );
};

export default BookPreview;
