export const buildOrder = ({
  draftId,
  selectedAddressId,
  numberOfPrints,
  isDigital,
  parentOrderId,
}) => {
  const addressId = selectedAddressId || '';
  const isDigitalOnly = numberOfPrints === 0 && isDigital;

  const body = {
    draft_id: draftId,
    quantity: numberOfPrints,
    isDigital: Boolean(isDigital),
    shippingAddressId: !isDigitalOnly ? addressId : '',
    parentOrderId: parentOrderId || undefined,
  };

  return body;
};

export function getEnv() {
  return import.meta.env.VITE_ENVIRONMENT || 'production';
}
