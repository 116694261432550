import axios from 'axios';
import qs from 'query-string';
import { merge } from 'lodash-es';

import env from 'environments';
import { getItem } from 'modules/dashboard/utils/legacy';

const instance = axios.create({
  baseURL: env.BBA_URL,
  paramsSerializer: qs.stringify,
});

instance.interceptors.request.use((config) => {
  const token = getItem('token');
  const Authorization = `Bearer ${token}`;

  return merge({}, config, {
    headers: {
      Authorization,
    },
  });
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      window.location = '/auth/login';
    }
    return Promise.reject(new Error('401 Unauthorized Error'));
  },
);

export const getInstance = () => instance;
