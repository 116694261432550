function BrewIcon() {
  return (
    <svg width="86" height="85" viewBox="0 0 86 85" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_11047_173290)">
        <g mask="url(#mask0_11047_173290)">
          <path
            d="M52.8782 44.5698L52.8297 41.6504L31.53 42.0004L31.4102 44.8752C31.4102 44.8752 50.5259 45.6153 52.8782 44.5698Z"
            fill="#CFB791"
          />
          <path
            d="M31.4102 44.4805L31.6184 66.3143C31.6184 66.3143 36.9334 72.2976 52.7736 66.3143L52.8779 44.0605C52.8779 44.0605 42.5609 45.1103 31.4102 44.4805Z"
            fill="#310E0A"
          />
          <path
            d="M52.8805 42.5777L52.925 42.0311L31.5234 41.9902L31.4336 42.5475L52.8805 42.5777Z"
            fill="#070707"
          />
          <path
            d="M42.1759 27.427L41.6508 17.1309L41.2775 23.5843L41.4795 27.3632L42.1759 27.427Z"
            fill="#080808"
          />
          <path
            d="M33.4989 42.441C33.8116 61.7554 33.8116 67.5287 33.2904 68.5784C32.7693 69.628 31.9355 71.6225 31.9355 71.6225L34.2283 72.1474C34.2283 72.1474 34.8536 69.4181 34.8536 66.584C34.8536 63.7499 34.6452 43.4908 34.3325 42.1262C34.0198 40.7616 33.4989 42.441 33.4989 42.441Z"
            fill="#A7A6AC"
          />
          <path
            d="M50.8448 42.6575C51.0296 61.9735 51.1782 67.745 51.7263 68.7806C52.2741 69.8163 53.1589 71.7883 53.1589 71.7883L50.8805 72.3729C50.8805 72.3729 50.1851 69.6609 50.1122 66.8277C50.0392 63.9946 49.7258 43.7368 50.0032 42.3645C50.2805 40.9922 50.8448 42.6575 50.8448 42.6575Z"
            fill="#C7C6CC"
          />
          <path
            d="M30.6816 38.6055V41.4134L52.5348 41.731L52.5337 38.6492L30.6816 38.6055Z"
            fill="#A7A6AC"
          />
          <path
            d="M52.402 38.2811L52.4465 34.0717L31.0448 33.8906L30.9551 38.1479L52.402 38.2811Z"
            fill="#070707"
          />
          <path
            d="M29.873 32.4434V33.5305L52.9842 33.8023L52.8943 32.4434H29.873Z"
            fill="#8A848E"
          />
          <path
            d="M30.8636 31.5379L52.176 31.7191C52.176 31.7191 52.3687 26.826 42.1942 27.0089C32.1224 27.1901 30.6838 28.1863 30.8636 31.5379Z"
            fill="#8A848E"
          />
          <path
            d="M43.5911 15.6596C43.5911 16.6852 42.8461 17.5165 41.9272 17.5165C41.0085 17.5165 40.2637 16.6852 40.2637 15.6596C40.2637 14.6341 41.0085 13.8027 41.9272 13.8027C42.8461 13.8027 43.5911 14.6341 43.5911 15.6596Z"
            fill="#080808"
          />
          <path
            d="M30.8626 33.9824L27.4453 36.0659L30.9525 36.6093L30.8626 33.9824Z"
            fill="#8A848E"
          />
          <path
            d="M52.8094 34.9597C52.8094 34.9597 57.7922 34.2089 61.2538 37.7304C62.8272 39.3311 64.4068 41.4166 64.498 45.5557C64.7903 58.801 55.8777 58.5066 54.855 58.6538C53.8322 58.801 52.3711 58.801 52.3711 58.801V56.2872C52.3711 56.2872 61.4999 56.5731 62.1422 47.6517C62.1755 47.1884 62.9448 36.6004 52.8329 37.3144L52.8094 34.9597Z"
            fill="black"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_11047_173290">
          <rect width="86" height="85" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default BrewIcon;
