const DeleteLeads = ({ count, allSelectedLeads }) => {
  let countPhrase = count === 1 ? 'this lead' : `these ${count} leads`;

  if (allSelectedLeads) {
    countPhrase = 'all leads';
  }

  return (
    <div>
      <p>Deleting {countPhrase} is permanent and cannot be undone.</p>
      <p>Are you sure you want to delete {countPhrase}?</p>
    </div>
  );
};

export default DeleteLeads;
