export const headerMapping = {
  'first name': 'firstName',
  'last name': 'lastName',
  email: 'email',
  'Member Email': 'customerEmail',
  Segments: 'segments',
  'phone number': 'phone',
  Date: null,
  address1: 'address.address1',
  address2: 'address.address2',
  city: 'address.city',
  zip: 'address.zip',
  state: 'address.state',
  country: 'address.country',
};

export const getHeaderValue = (header, target) => {
  const mappedKey = headerMapping[header];
  if (!mappedKey) return null;
  const keys = mappedKey.split('.');
  let value = target;
  for (const key of keys) {
    value = value ? value[key] : null;
  }
  return value;
};

export const getMappedField = (header) => {
  return headerMapping[header] || header;
};

export const createInvalidLeadErrorData = (mappedFieldsArr, invalidLead) => {
  const errorContent = invalidLead.fields.find((element) => element.field === mappedFieldsArr[0]);

  if (mappedFieldsArr.length > 1 && errorContent) {
    const errorMsgArr = errorContent.message.split(/, |: /);
    const field = mappedFieldsArr[1];
    const keyIndex = errorMsgArr.indexOf(field);
    let message = '';

    if (keyIndex >= 0) {
      message = errorMsgArr[keyIndex + 1];
    }

    return { field, message, value: errorContent.value[field] };
  }

  return errorContent;
};

export const formatErrorMsg = (invalidLead, header, value, isDuplicate) => {
  const mappedField = getMappedField(header);
  const mappedFieldsArr = mappedField.split('.');

  const fieldError = isDuplicate
    ? { field: header, value, message: 'Duplicate lead' }
    : createInvalidLeadErrorData(mappedFieldsArr, invalidLead);

  if (fieldError) {
    return fieldError;
  }
  return {
    field: header,
    value,
    message: '',
  };
};

export const formatInvalidLeads = (leadsData, importHeaders) => {
  const formattedInvalidLeads = [];

  leadsData.invalidImportList.forEach((invalidLead) => {
    const errors = [];

    importHeaders.forEach((header) => {
      const value = getHeaderValue(header, invalidLead.target);
      errors.push(formatErrorMsg(invalidLead, header, value));
    });

    formattedInvalidLeads.push(errors);
  });

  leadsData.duplicated.forEach((invalidLead) => {
    const errors = [];

    importHeaders.forEach((header) => {
      const value = getHeaderValue(header, invalidLead);
      errors.push(formatErrorMsg(invalidLead, header, value, true));
    });

    formattedInvalidLeads.push(errors);
  });

  return formattedInvalidLeads;
};
